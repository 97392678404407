import sdkInstanceProvider from "@src/client/sdk/sdkInstanceProvider";
import {
  initialise,
  createAdobeSource,
  createEventBusSource,
  createGtmSink,
  createOptimizelySink
} from "@asosteam/asos-web-event-tracking-bridge";
import { eventBus } from "@src/helpers/eventing/events";
import { GTM_ID } from "./constants";
import { noticeError } from "@src/helpers/monitoring";

const initialiseEventTrackingBridge = () => {
  const featuresDelegate = sdkInstanceProvider("features");
  const { s: adobeDelegate } = window;
  const newRelicDelegate = () => ({ noticeError });

  return initialise({
    sources: {
      adobe: createAdobeSource(adobeDelegate),
      eventBus: createEventBusSource(eventBus)
    },
    sinks: {
      gtm: createGtmSink(GTM_ID, newRelicDelegate),
      optimizely: createOptimizelySink(featuresDelegate)
    },
    newRelicDelegate: newRelicDelegate
  });
};

export default initialiseEventTrackingBridge;
