import {
  sdk,
  isAsosAuthTimeoutError,
  isAsosAuthStorageError,
  ACQUIRE_TOKEN_MODES
} from "@asosteam/asos-web-auth-client";
import {
  getStoreCode,
  getCountryCode,
  getKeyStoreDataversion,
  getDefaultLanguage
} from "../../template/state/modules/regionalStore";
import { AUTH_LAST_ACTION } from "../constants";
import { noticeError } from "@src/helpers/monitoring";

const INITALISE_FAILED_STATE = "INITALISE_FAILED_STATE";

const {
  SIGNIN_EMAIL,
  SIGNIN_SOCIAL,
  SIGNUP_EMAIL,
  SIGNUP_SOCIAL,
  LINK_ACCOUNTS
} = AUTH_LAST_ACTION;

const defaultGetAccessTokenOptions = {
  retries: 0,
  timeoutMs: 10_000
};

export const initialise = ({ store, getSubscriptionDetails }) => {
  const state = store.getState();
  const context = {
    store: getStoreCode(state),
    keyStoreDataversion: getKeyStoreDataversion(state),
    lang: getDefaultLanguage(state),
    country: getCountryCode(state)
  };

  const { authClient } = sdk({
    clientId: "www",
    scopes: ["openid", "profile", "offline_access", "sensitive"],
    context
  });

  const {
    isAuthenticated,
    isAnonymous,
    initialise,
    getCustomerAnonymousId,
    getIdTokenClaims,
    getAccessToken,
    redirectToLogin,
    redirectToSignUp,
    logout,
    getAuthHeaderPayload,
    isAuthHeaderCached
  } = authClient;

  const initialisePromiseBuilder = () =>
    initialise({ retries: 0, timeoutMs: 10_000 }).catch(error => {
      noticeError(error);
      return INITALISE_FAILED_STATE;
    });

  let initialisePromise = initialisePromiseBuilder();

  const requireInitialise =
    method =>
    async (...params) => {
      if ((await initialisePromise) === INITALISE_FAILED_STATE) {
        initialisePromise = initialisePromiseBuilder();
        await initialisePromise;
      }

      return method(...params);
    };

  const getAccessTokenInterface = async ({
    retries = defaultGetAccessTokenOptions.retries,
    timeoutMs = defaultGetAccessTokenOptions.timeoutMs
  } = defaultGetAccessTokenOptions) =>
    await requireInitialise(getAccessToken)({
      acquireTokenMode: ACQUIRE_TOKEN_MODES.SILENT,
      retries,
      timeoutMs
    });

  let idTokenClaims;
  const getClaim = requireInitialise(
    async (field, anonymousValue = undefined) => {
      try {
        idTokenClaims ??= getIdTokenClaims();

        return idTokenClaims[field];
      } catch (_) {
        return anonymousValue;
      }
    }
  );

  const profile = async () => {
    const lastAction = await getClaim("lastAction");

    return {
      customerId: await getClaim("customerId"),
      firstTimeBuyer: await getClaim("isFirstTimeBuyer"),
      firstName: await getClaim("firstName"),
      lastName: await getClaim("lastName"),
      customerAid: await requireInitialise(getCustomerAnonymousId)(),
      isAnonymous: await requireInitialise(isAnonymous)(),
      isRecognised: false,
      lastAction: {
        isSigninEmail: Boolean(lastAction === SIGNIN_EMAIL),
        isSigninSocial: Boolean(lastAction === SIGNIN_SOCIAL),
        isSignupEmail: Boolean(lastAction === SIGNUP_EMAIL),
        isSignupSocial: Boolean(lastAction === SIGNUP_SOCIAL),
        isLinkAccounts: Boolean(lastAction === LINK_ACCOUNTS)
      }
    };
  };

  const customerId = () => getClaim("customerId");

  const getAuthHeader = requireInitialise(
    async ({
      retries = defaultGetAccessTokenOptions.retries,
      timeoutMs = defaultGetAccessTokenOptions.timeoutMs
    } = defaultGetAccessTokenOptions) =>
      getAuthHeaderPayload({
        retries,
        timeoutMs
      })
  );

  const customer = {
    anonymousId: requireInitialise(getCustomerAnonymousId),
    customerGuid: () =>
      getClaim("customerGuid", requireInitialise(getCustomerAnonymousId)()),
    customerId,
    firstTimeBuyer: () => getClaim("isFirstTimeBuyer"),
    firstName: () => getClaim("firstName"),
    lastName: () => getClaim("lastName"),
    subscriptions: async () =>
      [await getClaim("customerSubscriptionCountry")].filter(Boolean),
    subscriptionDetails: () =>
      getSubscriptionDetails({ getCustomerId: customerId, getAuthHeader }),
    premierPropositionId: async () =>
      [await getClaim("premierPropositionId")].filter(Boolean),
    profile,
    isAuthenticated: requireInitialise(isAuthenticated),
    isRecognised: async () => false
  };

  return {
    customer,
    getAccessToken: getAccessTokenInterface,
    getAuthHeader,
    login: requireInitialise(redirectToLogin),
    signUp: requireInitialise(redirectToSignUp),
    logout: requireInitialise(logout),
    isAsosAuthTimeoutError,
    isAuthHeaderCached,
    isAsosAuthStorageError,
    getClaim
  };
};
